import React from 'react';

const ThankYou = () => {
  return (
    <div className={'thank-you-wrapper'}>

    </div>
  )
}

export default ThankYou
